
import Vue, { PropType } from 'vue';
import { VMoney } from 'v-money';
import { ConfiguredFlight, ProposalMarket } from '@/shared/types';

import {
  ValidationServiceContract,
  CalendarServiceContract,
  FlightModelContract,
  BaseProductModelContract,
  BudgetModelContract,
  KeywordsModelContract,
} from '@/injectables';
import { Models, Services } from '@/injectables/tokens';

import AdvancedOptionsDialog from './AdvancedOptionsDialog.vue';
import FlightConfigMap from './FlightConfigMap.vue';
import Geofence from './GeoFence.vue';
import { DateRangePicker } from '@/shared/ui';
import { RateType } from '@/app/graphql';
import KeywordsInput from '@/shared/ui/components/keywords-input.vue';

export default Vue.extend({
  name: 'OptionConfiguration',

  inject: ['showSnackbar'],

  useInjectable: [
    Services.Calendar,
    Services.Validation,
    Models.Flight,
    Models.BaseProduct,
    Models.Budget,
    Models.Keywords,
  ],

  props: {
    product: {
      type: Object,
      required: true,
    },
    flightNumber: {
      type: Number,
      required: true,
    },
    flight: {
      type: Object,
      required: true,
    },
    baseMarket: {
      type: Object as PropType<ProposalMarket>,
      default: () => ({}),
    },
    isConfigurePackage: {
      type: Boolean,
      default: false,
    },
    isPackageProduct: {
      type: Boolean,
      default: false,
    },
    isChangeDisabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    proposalBudgetMax: {
      type: Number,
      required: true,
    },
  },

  components: { KeywordsInput, FlightConfigMap, Geofence, AdvancedOptionsDialog, DateRangePicker },

  directives: { money: VMoney },

  data(): {
    initialRateValue: number;
    valid: boolean;
    money: {
      thousands: string;
      prefix: string;
      precision: number;
    };
    customRate: boolean;
    datesMenu: boolean;
    initialBudgetHasBeenSet: boolean;
    budgetIsFocused: boolean;
    openConfigMap: boolean;
    localBudget: number;
    range: string[];
    localSelectedAdFormatList: string[];
    rateFieldKey: number;
  } {
    return {
      localSelectedAdFormatList: [],
      localBudget: 0,
      initialRateValue: 0,
      openConfigMap: false,
      valid: true,
      initialBudgetHasBeenSet: false,
      money: {
        thousands: ',',
        prefix: '$',
        precision: 0,
      },
      datesMenu: false,
      customRate: false,
      budgetIsFocused: false,
      range: [this.flight.startDate, this.flight.endDate] || [],
      rateFieldKey: 0,
    };
  },

  watch: {
    budget(): void {
      this.$refs.budget.resetValidation();

      if (!this.loading) {
        this.$nextTick(() => {
          this.$refs?.flightForm.validate();
        });
      }
    },
    targetingOption() {
      this.initialRateValue = this.rate;
    },
  },

  methods: {
    keywordsUpdated(contextualKeywords: string[]) {
      this.updateFlight({ ...this.flight, contextualKeywords });
    },
    updateDemographics(demographics) {
      this.updateFlight({ ...this.flight, demographics });
    },
    updateAudience(audience) {
      this.updateFlight({ ...this.flight, audience });
    },
    updateAdvancedOptions(advancedOptions) {
      this.updateFlight({ ...this.flight, advancedOptions });
    },
    dateFormatter(date: Date) {
      return (this.calendarService as CalendarServiceContract).dateFormatter(date);
    },
    closeGeofence(): void {
      this.openConfigMap = false;

      this.updateFlight(this.flight);
    },
    clearGeoSelections(): void {
      const updatedFlight = { ...this.flight, market: { geoSelections: {} } };

      this.updateFlight(updatedFlight);
    },
    updateFlight(newFlight: ConfiguredFlight, recalculateProducts?: boolean): void {
      this.$emit('update-flight', {
        flight: { ...newFlight, isChanged: newFlight.budget !== this.flight.budget },
        flightId: this.flight.id,
        sendUpdate: true,
        ...(recalculateProducts && { recalculateProducts }),
      });
    },
    quantityUpdated(e) {
      const newVal = e.target.value;
      this.setBudgetFromQuantity(
        (this[Models.Budget] as BudgetModelContract).getBudgetFromQuantity(newVal, this.rate, this.rateType),
      );
    },
    validateRate(e) {
      const value = e?.target?.value;

      const { markupMax: max, markupMin: min } = this.flightConfigs[0];

      const { isErr, unwrapErr, unwrap } = (this.flightEntity as FlightModelContract).validateRate(
        value,
        this.defaultRate,
        this.initialRateValue,
        { min, max },
        this.isAdmin,
      );

      if (isErr()) {
        const { message, rate } = unwrapErr();
        this.showSnackbar(message, 'warning');
        this.rate = rate;
        // TODO: this.rateFieldKey is used as hack to rerender field.
        // Need to rework validate flow with field
        this.rateFieldKey = this.rateFieldKey + 1;
        return;
      }

      this.rate = unwrap();
    },
    onDateRangeInput(dates: string[]) {
      if (dates.length < 2) return;
      const [from, to] = dates;

      const formattedRange = `${this.dateFormatter(from)} - ${this.dateFormatter(to)}`;

      const validation = this.rules.minRange(formattedRange, this.productMinDays);

      if (validation && validation.length) return;

      const [startDate, endDate] = [...dates.sort((a, b) => Date.parse(a) - Date.parse(b))];

      const newFlight = {
        ...this.flight,
        rateType: this.rateType,
        rate: this.rate,
        startDate,
        endDate,
      };

      this.updateFlight(newFlight);
    },
    updateLocalBudget(e): void {
      try {
        this.localBudget = parseInt(e, 10);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('error', 'optionConfiguration.updateLocalBudget', e, err);
      }
    },
    handleRateFocus(): void {
      if (this.productIsSmartMailer || this.isCostPerFlightRate) {
        this.$refs.rate?.blur();
      }
    },
    handleFocus(): void {
      if (this.disabledBudget && !this.isRateQuote) {
        return this.$refs.budget?.blur();
      }
      this.budgetIsFocused = true;
    },
    onBudgetEdit(): void {
      if (this.localBudget < this.productMinSpend) return;

      this.budgetIsFocused = false;

      if ((this.budgetLock && !this.isRateQuote && !this.isRateCpm) || this.budget === this.localBudget) return;

      this.budget = Math.min(this.localBudget, this.proposalBudgetMax);
    },
    setDates(): void {
      if (this.range) {
        const [startDate, endDate] = this.range;
        this.range = [startDate, endDate];
      }
    },
    setBudgetFromQuantity(budget: number): void {
      if (budget === undefined || isNaN(budget)) {
        budget = 0; // need to handle input event or 'empty' input
      }

      budget = Math.max(0, budget);
      this.updateFlight({ ...this.flight, rateType: this.rateType, rate: this.rate, budget: budget, isLocked: true });
    },
  },

  mounted(): void {
    this.initialRateValue = this.rate;

    if (!this.budget && this.remainingBudget && !this.productIsSmartMailer) {
      this.budget = this.remainingBudget;
    }

    this.localBudget = this.budget;

    this.setDates();
  },

  computed: {
    showContextualKeywords() {
      return (this[Models.Keywords] as KeywordsModelContract).shouldShowContextualKeywords(this.currentFlightConfig);
    },
    shouldShowRateType(): boolean {
      return this.isRateConfigurable;
    },
    shouldShowRate(): boolean {
      return !this.hasPlatform && this.isRateConfigurable && !this.isRateQuote;
    },
    showShowQuantity(): boolean {
      return this.isRateCpm || this.isCostPerFlightRate;
    },
    disabledByPackage(): boolean {
      return this.isPackageProduct;
    },
    rateType() {
      if (!this?.flight?.targetingOption) return null;

      return this?.flight?.rateType || 'CPC';
    },
    currentFlightConfig() {
      if (!this.targetingOption) return {};
      return this.product.flightConfigs.find(c => c?.targetingOption === this.targetingOption) || {};
    },
    isRateConfigurable() {
      return (this[Models.BaseProduct] as BaseProductModelContract).isRateConfigurable(this.product);
    },
    isRateQuote() {
      return (this[Models.BaseProduct] as BaseProductModelContract).isRateTypeQuote(this.rateType);
    },
    isRateCpm() {
      return (this[Models.BaseProduct] as BaseProductModelContract).isRateTypeCpm(this.rateType);
    },
    productIsSmartMailer() {
      return (this[Models.BaseProduct] as BaseProductModelContract).fixedRateConfig(this.product);
    },
    isCostPerFlightRate() {
      return (this[Models.BaseProduct] as BaseProductModelContract).isCostPerFlightRate(this.flight);
    },
    hasOtt() {
      return (this[Models.BaseProduct] as BaseProductModelContract).hasOTT(this.flightConfigs);
    },
    hasPlatform(): boolean {
      return (this[Models.BaseProduct] as BaseProductModelContract).hasPlatform(this.flightConfigs);
    },
    totalFlightAllocation() {
      return (this[Models.BaseProduct] as BaseProductModelContract).productFlightTotalAllocated(this.product);
    },
    flightConfigs() {
      return this.product.flightConfigs || [];
    },
    primaryDropdownOptions() {
      return this.product?.flightConfigs?.filter(f => f?.targetingOption).map(f => f.targetingOption);
    },
    secondaryDropdownOptions() {
      return this.currentFlightConfig?.adFormatList || [];
    },
    isUnconfiguredFlight(): boolean {
      return !this.flight?.id;
    },
    platformList() {
      return this.currentFlightConfig.platformOption || [];
    },
    rules() {
      const validationService: ValidationServiceContract = this.validationService;

      return {
        required: validationService.requiredValidatorFactory(),
        requiredMultiple: validationService.maxLengthValidatorFactory(),
        minRange: validationService.minRangeValidatorFactory(),
        minBudget: validationService.minBudgetValidatorFactory(),
      };
    },
    isAdProductFieldDisabled(): boolean {
      return (
        !this.secondaryDropdownOptions ||
        !this.secondaryDropdownOptions.length ||
        this.loading ||
        this.disabledByPackage
      );
    },
    productMinDays(): number {
      return this.product?.minDays || 0;
    },
    productMinSpend(): number {
      return this.product?.minSpend || 0;
    },
    geofencesText(): { prefix: number; suffix: string } | null {
      if (this.isGeofence && !this.isConfigurePackage) {
        let suffix = 'geofences';

        const prefix = this.flight?.market?.geoSelections?.addressList?.length || 0;

        if (prefix === 1) {
          suffix = 'geofence';
        }

        return { prefix, suffix };
      }
      return null;
    },
    disabledBudget(): boolean {
      return (
        (this.budgetLock && !this.isRateCpm && !!this.budget) || this.productIsSmartMailer || this.isCostPerFlightRate
      );
    },
    budgetLock: {
      get(): boolean {
        return this.flight?.isLocked;
      },
      set(status: boolean): void {
        const newFlight = { ...this.flight, isLocked: status };

        this.updateFlight(newFlight);
      },
    },
    oneThousandDays(): string {
      const [from = new Date()] = this.range;
      return (this.calendarService as CalendarServiceContract).dateAfter(1000, new Date(from)).toISOString();
    },
    mapImage(): string {
      return require('@/assets/generic-map.png');
    },
    isGeofence(): boolean {
      return (this[Models.BaseProduct] as BaseProductModelContract).isGeofence(this.flightConfigs);
    },
    prettyBudget(): string {
      return this.$options.filters.formatPrice(this.budget);
    },
    configName(): string {
      return this.flightNumber ? `Flight ${this.flightNumber}` : '';
    },
    defaultRate() {
      if (!this.currentFlightConfig) return 0;
      return this.currentFlightConfig.rate;
    },
    quantity() {
      return (this[Models.Budget] as BudgetModelContract).getQuantityFromBudget(this.budget, this.rate, this.rateType);
    },
    rate: {
      get(): number {
        return this.flight?.rate || 0;
      },
      set(r: number): void {
        const newFlight = { ...this.flight, rate: r };

        this.updateFlight(newFlight);
      },
    },
    productBudgetMax(): number {
      return (this[Models.BaseProduct] as BaseProductModelContract).productBudgetMax(
        this.product.id,
        this.$store.state.newProposal.newProposal.budget,
        this.$store.state.newProposal.newProposal.products,
      );
    },
    smartMailerPrimaryDropdownOptions(): {}[] {
      if (this.productIsSmartMailer) {
        const totalFlightsMinusCurrent = this.totalFlightAllocation - this.budget;

        const rateForTargetingOption = (option: string): number => {
          const foundConfig = this.flightConfigs.find(config => config?.targetingOption === option);
          return foundConfig?.rate || 0;
        };

        return this.primaryDropdownOptions.map(option => {
          return {
            value: option,
            disabled: rateForTargetingOption(option) > this.productBudgetMax - totalFlightsMinusCurrent,
          };
        });
      }

      return [];
    },
    selectedPlatform: {
      get(): string {
        return this.flight?.selectedPlatform || '';
      },
      set(platform: string): void {
        const newFlight = { ...this.flight, selectedPlatform: platform };

        this.updateFlight(newFlight);
      },
    },
    targetingOption: {
      get(): string {
        return this.flight.targetingOption;
      },
      set(primary: string): void {
        const flightBasics = this.flightConfigs.find(f => f.targetingOption === primary) || null;

        const newFlight = {
          ...this.flight,
          targetingOption: primary,
          rateType: flightBasics.rateType,
          rate: flightBasics?.rate || 0, // override any rate when changing option type (probably this behavior would be changed)
          flightCategory: flightBasics?.flightCategory,
          ...(this.isRateQuote ? { isLocked: false } : {}),
        };

        const oldRateType = this.rateType;

        if ((this[Models.BaseProduct] as BaseProductModelContract).isFlightRateTypeQuote(flightBasics)) {
          newFlight.rate = newFlight.budget;
          newFlight.isLocked = true;
        } else if (this.productIsSmartMailer) {
          newFlight.budget = newFlight.rate;
          newFlight.isLocked = true;
        } else if ((this[Models.BaseProduct] as BaseProductModelContract).isCostPerFlightRate(newFlight)) {
          const newBudget =
            this.quantity && this.isCostPerFlightRate
              ? (this[Models.Budget] as BudgetModelContract).getBudgetFromQuantity(
                  this.quantity,
                  newFlight.rate,
                  newFlight.rateType,
                )
              : newFlight.rate;
          newFlight.budget = newBudget;
          newFlight.isLocked = true;
        }

        this.updateFlight(newFlight, true);

        const actualRemainingBudget = this.remainingBudget + this.budget;

        const newRateType = this.rateType;

        const needToUpdateBudget = oldRateType !== newRateType && newRateType === RateType.Costper;

        if (needToUpdateBudget) {
          let newBudget = 0;

          if (newRateType === RateType.Costper) {
            newBudget = parseInt(this.rate, 10);
          } else if (actualRemainingBudget > 0) {
            newBudget = actualRemainingBudget;
          }

          if (this.budget !== newBudget) {
            this.budget = newBudget;
          }
        }
      },
    },
    // techdebt: refactor this
    budget: {
      get(): number {
        return this.flight.budget;
      },
      set(budget: number): void {
        if (budget === undefined || isNaN(budget)) {
          budget = 0; // need to handle input event or 'empty' input
        }

        if (budget > this.product.budget && !this.isRateQuote) {
          // need to notify user that budget can't exceed remaining budget
          budget = this.product.budget;
        }

        budget = Math.max(0, budget);

        const oldRate = this.rate;

        const oldRateType = this.rateType;

        const newFlight = { ...this.flight, rateType: this.rateType, rate: this.rate, budget: budget };
        if (this.isRateQuote) {
          newFlight.rate = budget;
        }
        this.updateFlight(newFlight);

        const newRate = this.rate;

        const newRateType = this.rateType;

        if (oldRate !== newRate || oldRateType !== newRateType) {
          const updatedFlight = { ...this.flight, rate: this.rate, rateType: this.rateType };
          if (this.isRateQuote) {
            updatedFlight.rate = budget;
            updatedFlight.isLocked = true;
          }
          this.updateFlight(updatedFlight);
        }
      },
    },
    remainingBudget(): number {
      return this.product.budget - this.totalFlightAllocation;
    },
  },
});
