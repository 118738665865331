
import Vue, { PropType } from 'vue';
import OptionMap from './OptionMap.vue';
import { ProposalMarket, GeoObj, UnsafeAny } from '@/shared/types';
import { Models, Services } from '@/injectables/tokens';
import { CalendarServiceContract, ProductServiceContract, GeoModelContract } from '@/injectables';
import TargetedAudienceSelector from '@/components/Proposal/TargetAudienceSelector.vue';

export default Vue.extend({
  name: 'FlightConfigMap',

  useInjectable: [Services.Calendar, Models.NewProposal, Services.Product, Models.Geo],

  inject: ['showSnackbar'],

  props: {
    proposalMarket: {
      type: Object as PropType<ProposalMarket>,
      default: () => ({}),
    },
    flight: {
      type: Object,
      required: true,
    },
    productId: {
      type: String,
      required: true,
    },
    districts: {
      type: Array,
      default: () => [],
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    isChangeDisabled: {
      type: Boolean,
      default: false,
    },
  },

  components: { OptionMap, TargetedAudienceSelector },

  data: (): {
    mapHeight: number;
  } => ({
    mapHeight: 0,
  }),

  watch: {
    // geoSelections have been cleared and map should re-render when reset
    'flight.market': {
      deep: true,
      handler(newMarket, oldMarket): void {
        if (
          oldMarket?.geoSelections &&
          Object.keys(oldMarket.geoSelections).length &&
          newMarket?.geoSelections &&
          !Object.keys(newMarket.geoSelections).length &&
          this.$refs?.flightConfigMap
        ) {
          setTimeout(() => this.$refs.flightConfigMap.RenderMap(), 500);
        }
      },
    },
  },

  computed: {
    proposal() {
      return this.$store.state.newProposal.newProposal || {};
    },
    isDefaultDemographics(): boolean {
      return (
        (this.flight.audienceSegments === null ||
          (this.flight.audience === null && this.flight.demographics === null)) &&
        (this.flight.market === null || this.flight.market?.geoSelections === null)
      );
    },
    demographics: {
      get(): string[] {
        if (this.isDefaultDemographics) {
          return this.proposal.demographics || [];
        }
        return this.flight?.demographics || [];
      },
      set(value: string): void {
        if (this.isDefaultDemographics) {
          return this.updateAllDemographicsData({
            demographics: value,
          });
        }
        this.$emit('update-demographics', value);
      },
    },
    audience: {
      get(): string[] {
        if (this.isDefaultDemographics) {
          return this.proposal.audience || [];
        }
        return this.flight?.audience || [];
      },
      set(value: string): void {
        if (this.isDefaultDemographics) {
          return this.updateAllDemographicsData({
            audience: value,
          });
        }
        this.$emit('update-audience', value);
      },
    },
    openDialog: {
      get(): boolean {
        return this.dialog;
      },
      set(val: boolean): void {
        if (!val) {
          this.$emit('close-dialog');
        }
      },
    },
    dates(): string[] {
      return this.flight?.dates || [];
    },
    availsGeoName(): string {
      const allGeoSelections = [
        ...this.selectedStatesObjArr?.map(s => s?.name),
        ...this.selectedDistrictsObjArr?.map(d => d?.name),
        ...this.selectedDmas?.map(d => {
          return (this[Models.Geo] as GeoModelContract).cleanDmaString(d);
        }),
        ...this.selectedCounties?.map(d => {
          const [, state] = d?.key?.split('_');
          return `${state}, ${d?.name}`;
        }),
        ...this.selectedCitiesObjArr?.map(c => c?.name),
        ...this.selectedZips?.map(z => z?.name),
      ];

      const totalElements = allGeoSelections.length;

      return `${allGeoSelections?.[0] || ''}${totalElements > 1 ? ' (+' + String(totalElements - 1) + ')' : ''}`;
    },
    market(): ProposalMarket {
      if (this.isDefaultDemographics) {
        return { ...this.proposalMarket, geoSelections: this.proposal.market.geoSelections || {} };
      }
      return { ...this.proposalMarket, geoSelections: this.flight?.market?.geoSelections || {} };
    },
    selectedStates(): GeoObj[] {
      const { stateList = [], states = [] } = this.market?.geoSelections || {};

      return [...(stateList || []), ...this.forceArray(states)];
    },
    selectedCities(): GeoObj[] {
      const { cityList = [], cities = [] } = this.market?.geoSelections || {};

      return [...(cityList || []), ...this.forceArray(cities)];
    },
    selectedCounties(): GeoObj[] {
      const { countyList = [], counties = [] } = this.market?.geoSelections || {};

      return [...(countyList || []), ...this.forceArray(counties)];
    },
    selectedCitiesObjArr(): GeoObj[] {
      const { cityList = [], cities = [] } = this.market?.geoSelections || {};

      return [...(cityList || []), ...this.forceArray(cities)];
    },
    selectedStatesObjArr(): GeoObj[] {
      const { stateList = [], states = [] } = this.market?.geoSelections || {};

      return [...(stateList || []), ...this.forceArray(states)];
    },
    selectedZips(): GeoObj[] {
      const { zipList = [], zips = [] } = this.market?.geoSelections || {};

      return [...(zipList || []), ...this.forceArray(zips)];
    },
    selectedDistrictsObjArr(): GeoObj[] {
      const { districts = [] } = this.market?.geoSelections || {};

      return this.forceArray(districts || []);
    },
    selectedDistricts(): string[] {
      return this.selectedDistrictsObjArr;
    },
    selectedDmas(): GeoObj[] {
      const { dmaList = [], dmas = [] } = this.market?.geoSelections || {};

      return [...(dmaList || []), ...this.forceArray(dmas)];
    },
  },

  methods: {
    async updateAllDemographicsData({
      target = null,
      targetArray = null,
      demographics = null,
      audience = null,
    }: {
      target?: string | null;
      targetArray?: { key: string; name: string }[] | null;
      demographics?: null | any;
      audience?: null | any;
    } = {}): Promise<void> {
      target = target || Object.keys(this.market)[0];
      targetArray = targetArray || this.market[target];
      demographics = demographics || this.demographics;
      audience = audience || this.audience;

      const newGeoSelections = { ...this.market.geoSelections };
      newGeoSelections[(this[Models.Geo] as GeoModelContract).getGeoTypeList(target)] = targetArray;

      const flight = {
        ...this.flight,
        demographics,
        audience,
        market: { geoSelections: { ...newGeoSelections } },
      };

      this.$emit('update-flight', flight);
    },
    forceArray<T>(el: T | T[]): T[] {
      return Array.isArray(el) ? el : [el];
    },
    resetProduct(): void {
      this.$emit('clear-geo-selections');
    },
    async setGeoSelections({ target, targetArray }: { target: string; targetArray: UnsafeAny[] }): Promise<void> {
      if (this.isDefaultDemographics) {
        return this.updateAllDemographicsData({ target, targetArray });
      }
      this.$emit('set-geo-selections', { target, targetArray, productId: this.productId, flightId: this.flight.id });
    },
    onWindowResize(): void {
      this.mapHeight = this.$refs?.optionMapContainer.$el.clientHeight || 0;

      if (this.$refs?.flightConfigMap) this.$refs.flightConfigMap.adjustMap();
    },
  },

  mounted(): void {
    this.$store.dispatch('targetingSegments/getTargeting');
    window.addEventListener('optimizedResize', this.onWindowResize);

    this.$nextTick(() => {
      this.mapHeight = this.$refs?.optionMapContainer.$el.clientHeight || 0;
    });
  },

  beforeDestroy() {
    window.removeEventListener('optimizedResize', this.onWindowResize);
  },
});
