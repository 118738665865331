
import Vue, { PropType } from 'vue';
import WrapperWithTooltip from '@/components/WrapperWithTooltip.vue';
import { Models } from '@/injectables/tokens';
import { BaseProductModelContract } from '@/injectables';
import { Product } from '@/shared/types';

export default Vue.extend({
  name: 'ProductOptionContainer',

  useInjectable: [Models.BaseProduct],

  components: { WrapperWithTooltip },

  props: {
    category: {
      type: String,
    },
    name: {
      type: String,
    },
    budget: {
      type: Number,
    },
    active: {
      type: Boolean,
    },
    hideControlButtons: {
      type: Boolean,
    },
    sandbox: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    isChangeDisabled: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object as PropType<Product>,
      default: () => {},
    },
  },

  computed: {
    dialogIsOpen: {
      get(): boolean {
        return this.active;
      },
      set(): void {
        this.$emit('close-config');
      },
    },
    productIcon(): string {
      return (this[Models.BaseProduct] as BaseProductModelContract).categoryIconAndColor(this.category).icon;
    },
    getReadableCategory() {
      return (this[Models.BaseProduct] as BaseProductModelContract).getReadableCategory(this.category);
    },
    showAlert() {
      return (this[Models.BaseProduct] as BaseProductModelContract).noneTargetingSupport(this.product);
    },
    alertStyle() {
      return {
        bottom: (this.hideControlButtons ? 0 : 72) + 'px',
      };
    },
  },

  methods: {
    cancelOption(): void {
      if (this.sandbox) {
        // TODO: create more reuseable cancel option
        this.$store.dispatch('product/resetOTTMarket').catch(err => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
      }
      this.dialogIsOpen = false;
      this.$emit('cancel-config');
    },
  },
});
