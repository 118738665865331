
import Vue, { PropType } from 'vue';

type XmlBroadcast = {
  station: string;
  programName: string;
  days: string[];
  spend: string;
  rating: string;
  spots: number;
  startDate: number;
  endDate: number;
};

export default Vue.extend({
  name: 'NewXMLProductTable',

  props: {
    broadcast: {
      type: Array as PropType<XmlBroadcast[]>,
      required: true,
    },
  },
  methods: {
    daysString(days: string[]) {
      const daysOfWeekMapper = {
        sunday: 0,
        monday: 1,
        tuesday: 2,
        wednesday: 3,
        thursday: 4,
        friday: 5,
        saturday: 6,
      };
      const forceArray: <T>(el: T) => T[] = el => (Array.isArray(el) ? el : [el]);
      const sortedDays = days
        .slice()
        .sort((a, b) => daysOfWeekMapper[a.toLowerCase()] - daysOfWeekMapper[b.toLowerCase()]);
      const result: any[] = [sortedDays[0]];
      for (let i = 0; i < sortedDays.length - 1; i++) {
        if (daysOfWeekMapper[sortedDays[i + 1].toLowerCase()] - daysOfWeekMapper[sortedDays[i].toLowerCase()] > 1) {
          if (Array.isArray(result[result.length - 1])) {
            result[result.length - 1][1] = sortedDays[i];
          }
          result.push(sortedDays[i + 1]);
        } else {
          result[result.length - 1] = [forceArray(result[result.length - 1])[0], sortedDays[i + 1]];
        }
      }
      return result
        .map(day => (Array.isArray(day) ? day.map(day => day.slice(0, 3)).join('-') : day.slice(0, 3)))
        .join(', ');
    },
    formatDates(entry) {
      if (!entry.startTime || !entry.endTime) return null;
      return (
        new Date(entry.startTime).toLocaleString('en-US') + ' - ' + new Date(entry.endTime).toLocaleString('en-US')
      );
    },
  },
  computed: {
    programsCount(): number {
      const s = new Set(this.broadcast.map(p => p.programName));
      return s.size;
    },
    spotsCount(): number {
      return this.broadcast.reduce((acc, p) => acc + Number.parseInt(p.spots), 0);
    },
    totalSpend(): number {
      return this.broadcast.reduce((acc, p) => acc + Number.parseFloat(p.spend), 0);
    },
  },
});
