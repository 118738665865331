
import { BaseProductModelContract } from '@/injectables';
import { Models } from '@/injectables/tokens';
import Vue from 'vue';

export default Vue.extend({
  name: 'ProductTableItem',

  useInjectable: [Models.BaseProduct],

  props: {
    product: {
      type: Object,
      required: true,
    },
    isPackageBlocked: {
      type: Boolean,
      default: false,
    },
    isProductsConfig: {
      type: Boolean,
      default: false,
    },
    isChangeDisabled: {
      type: Boolean,
      default: false,
    },
    categoryIcon: {
      type: String,
    },
    markColor: {
      type: String,
      default: 'grey',
    },
    selected: {
      type: Boolean,
    },
    disabledByLoading: {
      type: Boolean,
    },
  },
  computed: {
    borderedCategory(): string {
      const color = this.product.disabled || this.isChangeDisabled ? 'grey' : this.markColor;
      return `border-left: 4px solid ${color}`;
    },
    productIsDisabled(): boolean {
      return this.isChangeDisabled || this.product.disabled || this.isPackageBlocked;
    },
    disabledTextClass(): string {
      return this.productIsDisabled ? 'grey--text lighten-2' : '';
    },
    tooltipTextMessage(): string {
      if (this.product.disabledByDates) {
        return 'Please increase proposal date range to proceed';
      }
      if (this.isPackageBlocked) {
        return 'Some products of this package have already been added to proposal';
      }
      return 'Please increase budget or remove another product to proceed';
    },
    readableCategory() {
      return (this[Models.BaseProduct] as BaseProductModelContract).getReadableCategory(this.product.category);
    },
  },
});
