
import Vue from 'vue';
import { ProductConfig, PackageConfig } from '@/shared/types';
import { DataTableHeader } from 'vuetify';
import { BaseProductModelContract, PackageProductModelContract, ValidationServiceContract } from '@/injectables';
import { Models, Services } from '@/injectables/tokens';
import ProductItem from './ProductsSelectTable/ProductItem.vue';
import { ProductConfigCategory } from '@/app/graphql';

export default Vue.extend({
  name: 'ProductSelectDialog',

  useInjectable: [Models.Package, Models.PackageProduct, Models.BaseProduct],

  props: {
    active: {
      type: Boolean,
    },
    productsList: {
      type: Array,
      required: true,
    },
    selectedProducts: {
      type: Array,
      required: true,
    },
    isProductsConfig: {
      type: Boolean,
      default: false,
    },
    isChangeDisabled: {
      type: Boolean,
      default: false,
    },
    selectedPackage: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  components: {
    ProductItem,
  },
  data: (): {
    headers: DataTableHeader[];
  } => ({
    headers: [
      { text: 'Category', align: 'start', value: 'category' },
      { text: 'Product', value: 'name' },
      { text: 'Min. Spend', value: 'minSpend' },
      { text: 'Rec. Spend', value: 'recommendedBudget' },
      { text: 'Min. Days', value: 'minDays' },
      { text: '', value: '', align: 'end', sortable: false },
    ],
  }),

  filters: {
    formatCategory(category: string): string {
      return category ? `${category.charAt(0).toUpperCase()}${category.slice(1, category.length)}` : '';
    },
  },

  methods: {
    onProductSelect(id: string): void {
      if (this.productIsSelected(id)) {
        this.removeProduct(id);
      } else {
        this.addProduct(id);
      }
    },
    productIsSelected(id: string): boolean {
      const productIndex = this.selectedProducts.findIndex(product => product.id === id);
      return productIndex !== -1;
    },
    productIsSelectedInPackage(id: string): boolean {
      let result = false;
      for (const product of this.selectedProducts) {
        if (Array.isArray(product.products)) {
          result = product.products.some(packageProduct => packageProduct.id === id);
          if (result) break;
        }
      }

      return result;
    },
    categoryIconAndColor(category) {
      return (this[Models.BaseProduct] as BaseProductModelContract).categoryIconAndColor(category);
    },
    removeProduct(id: string): void {
      this.$emit('remove-product', id);
    },
    addProduct(id: string): void {
      this.$emit('add-product', {
        id,
        xmlProductsOnly: this.xmlProductsOnly,
      });
    },
    packageIsBlocked(product) {
      return this.packageEntity.isPackageBlocked(product, this.selectedProducts);
    },
    canAddProductByBudget(product) {
      if (this.isProductsConfig)
        return (this[Models.PackageProduct] as PackageProductModelContract).canAddNewProduct({
          minSpend: product.calcMinSpend,
          products: this.selectedProducts,
          productBudget: product.budget,
          solutionBudget: this.$store.state.product.newPackage.budget,
        });

      return (this[Models.BaseProduct] as BaseProductModelContract).canAddNewProduct({
        minSpend: product.calcMinSpend,
        products: this.selectedProducts,
        productBudget: product.budget,
        solutionBudget: this.$store.state.newProposal.newProposal.budget,
      });
    },
  },

  computed: {
    rules() {
      const validationService: ValidationServiceContract = this.$container.get(Services.Validation);
      return {
        minRange: validationService.minRangeValidatorFactory(),
      };
    },
    productsPerPage(): number {
      // Maybe need to add pagination
      return this.productsList?.length || 0;
    },
    saveProductsLoading(): boolean {
      return this.$store.state.newProposal.updateProductsLoading || this.$store.state.newProposal.saveProgressLoading;
    },
    xmlProductsOnly(): boolean {
      if (!Array.isArray(this.selectedProducts)) {
        return false;
      }

      const filteredProducts = this.selectedProducts.filter(p => p?.name && p?.id);

      if (!filteredProducts.length) return false;
      return filteredProducts.every(
        p =>
          (this[Models.BaseProduct] as BaseProductModelContract).xmlConfiguredProduct(p) ||
          (this[Models.BaseProduct] as BaseProductModelContract).noXmlConfiguredProduct(p),
      );
    },
    products(): ProductConfig[] {
      const productIsDisabled = (product: ProductConfig): boolean => {
        return (
          (!this.canAddProductByBudget(product) && !this.productIsSelected(product.id)) ||
          this.xmlProductsOnly ||
          this.productIsSelectedInPackage(product.id)
        );
      };

      const productIsDisabledByDate = (productCandidate): boolean => {
        const { minDays = 0 } = productCandidate;
        if (!minDays) {
          return false;
        }
        const { startDate, endDate } = this.$store.state['newProposal'].newProposal;
        const dateRange = `${new Date(startDate)} - ${new Date(endDate)}`;
        const validation = this.rules.minRange(dateRange, minDays);
        return !!validation?.length && !this.isProductsConfig;
      };

      const packageDisabled = (product: PackageConfig): boolean => {
        if (
          product &&
          Array.isArray(product?.products) &&
          product?.category === ProductConfigCategory.Package &&
          this.selectedPackage?.id !== product?.id
        ) {
          return Boolean(this.selectedPackage && this.selectedPackage?.id);
        } else {
          return false;
        }
      };
      return this.productsList.map(config => {
        return {
          ...config,
          disabled: productIsDisabled(config) || productIsDisabledByDate(config) || packageDisabled(config),
          disabledByDates: productIsDisabledByDate(config),
        };
      });
    },
    dialogIsOpen: {
      get(): boolean {
        return this.active;
      },
      set(): void {
        this.$emit('close-product-select');
      },
    },
    width() {
      return this.$vuetify.breakpoint.mdAndDown ? '80vw' : '50vw';
    },
  },
});
