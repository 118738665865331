
import { ConfiguredFlight, ConfiguredProduct, ProposalMarket } from '@/shared/types';
import OptionConfiguration from './OptionConfiguration.vue';
import Vue, { PropType } from 'vue';
import { BaseProductModelContract } from '@/injectables';
import { Models } from '@/injectables/tokens';
import { MapType } from '@/app/graphql';

export default Vue.extend({
  props: {
    product: {
      type: Object as PropType<ConfiguredProduct>,
      required: true,
    },
    baseMarket: {
      type: Object as PropType<ProposalMarket>,
      default: () => ({}),
    },
    isPackageProduct: {
      type: Boolean,
      default: false,
    },
    isChangeDisabled: {
      type: Boolean,
      default: false,
    },
    isConfigurePackage: {
      type: Boolean,
      default: false,
    },
    proposalBudgetMax: {
      type: Number,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  components: { OptionConfiguration },

  useInjectable: [Models.BaseProduct],

  name: 'FlightsWrapper',

  methods: {
    addNewFlight(): void {
      if (!this.canAddNewFlight) return;

      this.$emit('add-flight', { productId: this.product.id });
    },
    updateFlight(flightObj: {
      flight: ConfiguredFlight;
      flightId: string;
      sendUpdate?: boolean;
      recalculateProducts?: boolean;
    }): void {
      this.$emit('update-flight', { ...flightObj, productId: this.product.id });
    },
    removeFlight(flightId): void {
      this.$emit('remove-flight', { flightId, productId: this.product.id });
    },
  },

  computed: {
    flightConfigs() {
      return this.product.flightConfigs;
    },
    hasUnconfiguredFlights(): boolean {
      return this.flights.some(f => !f.budget);
    },
    canAddNewFlight(): boolean {
      if (this.isChangeDisabled || this.hasUnconfiguredFlights) return false;

      const isGeofence = this.flightConfigs?.some(f => f?.mapType === MapType.Radius);

      if (isGeofence && !this.isConfigurePackage) {
        return this.flights.every(f => f?.market?.geoSelections?.addressList?.length);
      }

      return true;
    },
    productMinSpend(): number {
      return this.product.minSpend;
    },
    flights(): ConfiguredFlight[] {
      return this.product?.flights || [];
    },
  },
});
