
import Vue from 'vue';
import { Services, Models } from '@/injectables/tokens';
import { EnvServiceContract, FilesModelContract } from '@/injectables';
import BasicFileUploader from './BasicFileUploader.vue';
import { ProductXmlFormat } from '@/app/graphql';

export default Vue.extend({
  name: 'XmlUploader',
  inject: ['showSnackbar'],
  components: {
    BasicFileUploader,
  },
  useInjectable: [Services.Env, Models.Files],
  data(): { xmlType: ProductXmlFormat } {
    return {
      xmlType: ProductXmlFormat.Aos,
    };
  },
  props: {
    showProgressLoading: {
      type: Boolean,
      default: false,
    },
    mainText: {
      type: String,
    },
    dropUploading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fileFormats(): string[] {
      return ['xml'];
    },
    availableTypes() {
      return ['application/', 'text/'].flatMap(type => this.fileFormats.map(el => type + el));
    },
    fileValidator() {
      const envService = this.envService as EnvServiceContract;
      const sizeLimit = 2 * envService.getOrThrow<number>('VUE_APP_UPLOAD_SIZE_LIMIT', v => parseInt(v)); // 2MB
      const filesEntity = this.filesEntity as FilesModelContract;
      return [
        filesEntity.getInvalidFileValidator({
          message: 'File must be a xml file',
        }),
        filesEntity.getEmptyFileValidator({ message: "File mustn't be an empty file" }),
        filesEntity.getFormatFileValidator({
          formats: this.fileFormats,
          availableTypes: this.availableTypes,
          message: 'File must be a valid xml file',
        }),
        filesEntity.getSizeValidator({
          size: sizeLimit,
          message: 'File must be less than 2MB',
        }),
      ];
    },
  },
  methods: {
    onFilePicked(file: File): void {
      this.$emit('upload-xml-file', { file, xmlType: this.xmlType });
    },
  },
});
