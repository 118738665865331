
import { BaseProductModelContract } from '@/injectables';
import { Models } from '@/injectables/tokens';
import Vue from 'vue';

export default Vue.extend({
  name: 'ProductInfo',

  useInjectable: [Models.BaseProduct],

  props: {
    category: {
      type: String,
    },
    icon: {
      type: String,
    },
    withoutIconBackground: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    readableCategory(): string {
      return (this[Models.BaseProduct] as BaseProductModelContract).getReadableCategory(this.category);
    },
  },
});
