
import Vue from 'vue';

export default Vue.extend({
  name: 'LockBudgetButton',
  props: {
    isLocked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
});
