
import Vue, { PropType } from 'vue';

type XmlBroadcast = {
  StationCallLetters: string;
  AvailName: string;
  DaysString: string;
  StartTime: string;
  EndTime: string;
  SumSpots: number;
  DetailedPeriodList: { Rating: string; Spend: string };
};

export default Vue.extend({
  name: 'OldXMLProductTable',

  inject: ['showSnackbar'],

  data: (): {
    noDocumentLoading: boolean;
    loading: false;
  } => ({
    noDocumentLoading: false,
    loading: false,
  }),

  props: {
    broadcast: {
      type: Array as PropType<XmlBroadcast[]>,
      required: true,
    },
  },
  methods: {
    calculateRating(entry): number {
      return entry.DetailedPeriodList.reduce((acc, item) => {
        const rating = Number.parseFloat(item.Rating);
        if (!Number.isNaN(rating)) {
          acc += rating;
        }

        return acc;
      }, 0).toFixed(1);
    },
    calculateSpend(entry): number {
      // TODO: use vue filters to format price correctly
      return entry.DetailedPeriodList.reduce((acc, item) => {
        const spend = Number.parseFloat(item.Spend);
        if (!Number.isNaN(spend)) {
          acc += spend;
        }

        return acc;
      }, 0).toFixed(0);
    },
  },
});
