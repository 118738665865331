
import Vue from 'vue';

export default Vue.extend({
  name: 'ProductStatusContainer',
  props: {
    readonly: {
      type: Boolean,
    },
    status: {
      type: String,
    },
  },
});
